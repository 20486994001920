import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";
import close from "../../assets/svg/close.svg";
import config from '../../Helpers/config.json'
import "./style.css";
import ErrorToast from "../../utills/error-toast";
import SuccessToast from "../../utills/success-toast";

const SignupModal = ({show, onHide, setShowLoginPopup, closeAll}) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNo: '',
      password: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Name is Required"),
      email: Yup.string()
        .email("Invalid Email address")
        .required("Email is Required"),
      password: Yup.string().required("Password Required"),
    }),
  });

  const signUp = async () => {
    setLoading(true)
    try{
      fetch(`${config['baseUrl2']}auth/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "name": formik.values.fullName,
            "email": formik.values.email,
            "phoneNumber":  formik.values.phoneNo,
            "password": formik.values.password,
            //"baseUrl": 'http://localhost:3000/',
            "baseUrl": config['appUrl']
        })
    })
        .then(response => response.json())
        .then(res => {
            if (res.success) {
                setLoading(false)
                onHide()
                setShowLoginPopup(true)
                SuccessToast('Email has been send. Please verify your account')
            } else {
                if (res.message?.includes('Email already exists in Firebase')){
                   ErrorToast('Email already exists')
                }
                else{
                  ErrorToast(res.message)
                }
                setLoading(false)
            }
        })
        .catch((error) => {
            setLoading(false)
            ErrorToast(error.message)
        });
    }
    catch(err){
      console.error('Error hai 2 ----> ', err);
      ErrorToast(err.message)
    }
};

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      className="login-modal"
      centered
    >
      <span onClick={closeAll}>
        <img src={close} alt="" className="close-icon" />
      </span>
      <Modal.Body className="d-flex flex-column align-items-center ">
        <div className="w-100 header-custom">
          <p>Sign Up</p>
        </div>
        <Form.Group className="mb-3 input-field">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Full Name"
            id="fullName"
            name="fullName"
            value={formik.values.fullName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.touched.fullName && !!formik.errors.fullName}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.fullName}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3 input-field">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.touched.email && !!formik.errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 input-field">
          <Form.Label>Phone No</Form.Label>
          <Form.Control
            type="text"
            placeholder="+11234567890 (Optional)"
            id="phoneNo"
            name="phoneNo"
            value={formik.values.phoneNo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Group>
        <Form.Group className="mb-3 input-field">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.touched.password && !!formik.errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.password}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="login-navigate-ref">
          <p>Already have an account? <span className="prominent-text" onClick={()=> {onHide() ; setShowLoginPopup(true)}}>Login here!</span></p>
        </div>
        <Button className="auth-action-btn" onClick={signUp} variant="primary">
          {loading ? <Spinner animation="border" size={5} /> : "Sign Up"}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default SignupModal;
