import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { GlobalWorkerOptions, getDocument } from "pdfjs-dist/legacy/build/pdf";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import jsQR from "jsqr";
import { version } from "pdfjs-dist";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import "./style.css";
import FeedbackModal from "../FeedbackModal/FeedbackModal";
import ReactStars from "react-rating-stars-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.js`;

const PdfUpdateExtractor = ({ data }) => {
  const [pagesData, setPagesData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [animated, setAnimated] = useState(0);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [rating, setRating] = useState(0);  // Initially set to 0


  const mainContentRef = useRef(null);

  const ratingChanged = (newRating) => {
    console.log("New rating is: " + newRating);
  //   setAnimated(true);
  //    // Display a toast message when a star is clicked
  // toast.success("Thanks! Looking forward to more!", {
  //   position: "top-center",
  //   autoClose: 2000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  // });
  //   // Handle the rating change (e.g., update state, make an API call)
  };

  const addAlphaChannelToUint8ClampedArray = (unit8Array, imageWidth, imageHeight) => {
    const newImageData = new Uint8ClampedArray(imageWidth * imageHeight * 4);
    for (let j = 0, k = 0, jj = imageWidth * imageHeight * 4; j < jj;) {
      newImageData[j++] = unit8Array[k++];
      newImageData[j++] = unit8Array[k++];
      newImageData[j++] = unit8Array[k++];
      newImageData[j++] = 255;
    }
    return newImageData;
  };

  const getPageImages = async (pageNum, pdfDocumentInstance) => {
    try {
      const pdfPage = await pdfDocumentInstance.getPage(pageNum);
      const operatorList = await pdfPage.getOperatorList();
      const validObjectTypes = [
        pdfjsLib.OPS.paintImageXObject,
        pdfjsLib.OPS.paintImageXObjectRepeat,
        pdfjsLib.OPS.paintJpegXObject,
      ];

      const imagePromises = operatorList.fnArray.map((element, idx) => {
        if (validObjectTypes.includes(element)) {
          return new Promise((resolve) => {
            const imageName = operatorList.argsArray[idx][0];
            pdfPage.objs.get(imageName, async (image) => {
              const imageUnit8Array = image.data;
              const imageWidth = image.width;
              const imageHeight = image.height;
              const imageUint8ArrayWithAlphaChannel =
                addAlphaChannelToUint8ClampedArray(
                  imageUnit8Array,
                  imageWidth,
                  imageHeight
                );
              const imageData = new ImageData(
                imageUint8ArrayWithAlphaChannel,
                imageWidth,
                imageHeight
              );
              const canvas = document.createElement("canvas");
              canvas.width = imageWidth;
              canvas.height = imageHeight;
              const ctx = canvas.getContext("2d");
              ctx.putImageData(imageData, 0, 0);
              const imageSrc = canvas.toDataURL();
              const decodedData = jsQR(
                imageUint8ArrayWithAlphaChannel,
                imageWidth,
                imageHeight
              );
              resolve({
                imageSrc,
                decodedData: decodedData ? decodedData.data : null,
              });
            });
          });
        }
      });

      const images = await Promise.all(imagePromises);
      return {
        images: images.filter(Boolean),
      };
    } catch (error) {
      console.log(error);
      return {
        images: [],
      };
    }
  };

  const extractText = async (pageNum, pdfDocumentInstance) => {
    try {
      const page = await pdfDocumentInstance.getPage(pageNum);
      const textContent = await page.getTextContent();
      const pageText = textContent.items.map((item) => item.str).join(" ");

      return {
        text: pageText,
      };
    } catch (error) {
      console.error("Error extracting text:", error);
      return {
        text: "",
      };
    }
  };

  const fetchAndProcessPdf = async (url) => {
    try {
      setLoading(true);
      setLoadingProgress(0);

      const response = await axios.get(url, {
        responseType: 'arraybuffer',
        onDownloadProgress: progressEvent => {
          const { loaded, total } = progressEvent;
          const percentage = Math.round((loaded * 100) / total);
          setLoadingProgress(percentage);
        }
      });

      const loadingPdfDocument = getDocument({ data: response.data });

      const pdfDocumentInstance = await loadingPdfDocument.promise;
      const totalNumPages = pdfDocumentInstance.numPages;

      setTotalPages(totalNumPages);

      // Load the initial page
      await loadPageData(currentPage, pdfDocumentInstance);

      setLoading(false);
      setLoadingProgress(100); // loading complete
    } catch (error) {
      console.log(error);
    }
  };

  const loadPageData = async (pageNum, pdfDocumentInstance) => {
    const pageImages = await getPageImages(pageNum, pdfDocumentInstance);
    const pageText = await extractText(pageNum, pdfDocumentInstance);

    setPagesData((prevPagesData) => ({
      ...prevPagesData,
      [pageNum]: {
        ...pageImages,
        ...pageText,
      },
    }));
  };

  const renderTextWithLinks = (text) => {
    // Replace only 'www.fictiongenie.com' with a hyperlink
    const linkText = 'www.fictiongenie.com';
    const linkHref = 'http://' + linkText; // Add 'http://' if missing

    // Use a regular expression to ensure exact match
    const parts = text.split(new RegExp(`(${linkText})`, 'i'));

    return parts.map((part, index) =>
      part.toLowerCase() === linkText.toLowerCase() ? (
        <a key={index} href={linkHref} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };






  useEffect(() => {
    if (data?.pdfPath) {
      setAnimated(true)
      fetchAndProcessPdf(data.pdfPath);
    }
  }, [data?.pdfPath]);

  useEffect(() => {
    const loadAdjacentPages = async () => {
      const url = data?.pdfPath;
      if (url && totalPages > 0) {
        const pdfDocumentInstance = await getDocument(url).promise;

        if (!pagesData[currentPage]) {
          await loadPageData(currentPage, pdfDocumentInstance);
        }

        if (currentPage < totalPages && !pagesData[currentPage + 1]) {
          await loadPageData(currentPage + 1, pdfDocumentInstance);
        }
      }
    };

    loadAdjacentPages();
  }, [currentPage, data?.pdfPath, totalPages]);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setAnimated(true)
      setRating(0);  // Reset rating on navigation
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setAnimated(true)
      setRating(0);  // Reset rating on navigation
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const handleFinish = () => {
    console.log('finished')
    setShowFeedbackModal(true)
  };
  useEffect(() => {
    if (animated) {
      const timer = setTimeout(() => setAnimated(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [animated]);

  useEffect(() => {
    const scrollToMainContent = () => {
      if (mainContentRef.current) {
        mainContentRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    scrollToMainContent();
  }, [currentPage]);

  if (loading) {
    return (
      <div className="pdf-container">
        <Spinner animation="border" className="spinner" />
        <p className="mx-4 mt-4">Story is on the way... {loadingProgress}%</p>
      </div>
    );
  }

  return (
    <div className={animated && "container-pdf-extract"}>
      <section className="py-5">
        <Container className="px-4 px-lg-5 my-5">
        <ToastContainer />
          <Row className="gx-4 gx-lg-5 align-items-center" ref={mainContentRef}>
            <Col md={6}>
              {pagesData[currentPage]?.images[0]?.imageSrc && (
                <img
                  className="card-img-top mb-5 mb-md-0 pdf-img"
                  src={pagesData[currentPage].images[0].imageSrc}
                  alt="..."
                />
              )}
            </Col>
            <Col md={6}>
              <div className="pdf-content">
                {/* <div className="small mb-1">{`Book-Id: ${data?.id || ""}`}</div> */}
              {/* Conditionally render the title based on the page number */}
              {currentPage !== totalPages && data?.title && (
                  <h1 className="display-5 fw-bolder">{data.title}</h1>
                )}
              <div className="fs-5 mb-5">
                  <p style={{ fontStyle: currentPage === totalPages ? 'italic' : 'normal' }}>
                  {currentPage === totalPages
                    ? renderTextWithLinks(pagesData[currentPage]?.text || '')
                    : pagesData[currentPage]?.text || ''}
                </p>
                              {/* Star Rating Component */}
              <div className={animated && "mb3" && rating}>

<ReactStars
  key={currentPage}
  count={5} // Total number of stars
  onChange={ratingChanged} // Function to handle rating change
  size={24} // Default size of stars, explicitly set
  activeColor="#ffd700" // Default active color, explicitly set
  isHalf={false} // Example: Default allowing half-stars, explicitly set if needed
  value={rating} // Default initial value, explicitly set if needed
/>

</div>

                </div>

                <p className="lead">{data?.description || ""}</p>
              </div>


              <div className="d-flex justify-content-center">

                <Button
                  onClick={handlePrevious}
                  disabled={currentPage === 1}
                  className="flex-shrink-0"
                  type="button"
                  style={{
                    color: "white",
                    borderColor: "black",
                    background: "black",
                  }}
                >
                  Previous
                </Button>
                <span className="w-100px d-flex justify-content-center align-items-center">{`${currentPage} of ${totalPages}`}</span>
                <Button
                  onClick={currentPage === totalPages ? handleFinish : handleNext}
                  // disabled={currentPage === totalPages}
                  className="flex-shrink-0"
                  type="button"
                  style={{
                    color: "white",
                    borderColor: "black",
                    background: "black",
                  }}
                >
                  {currentPage !== totalPages ? 'Next' : 'Finish'}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {
        showFeedbackModal && (
          <FeedbackModal
            showModal={showFeedbackModal}
            onHide={() => setShowFeedbackModal(false)}
            bookId={data?.id}
          />
        )
      }
    </div>
  );
};

export default PdfUpdateExtractor;
