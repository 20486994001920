import React, { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap'
import Home from './components/Home/Home'
import Books from './components/Books/Books'
import BookDetails from './components/BookDetails/BookDetails'
import AddBook from './components/AddBook/AddBook'
import UpdateBook from './components/UpdateBook/UpdateBook'
import Stories from './components/Stories/Stories'
import './App.css'
import PdfMain from './components/PdfMain/PdfMain'
import AddProfile from './components/AddProfile/add-profile'
import logo from './assets/logo.jpeg'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggenIn] = useState(localStorage.getItem('token'))
  const [user, setUser] = useState(localStorage.getItem('token'))
  const [expanded, setExpanded] = useState(false)

  const logout = () => {
    localStorage.removeItem('data')
    localStorage.removeItem('email')
    localStorage.removeItem('token')
    localStorage.removeItem('uid')
    localStorage.removeItem('role')
    setIsLoggenIn(false)
    handleNavLinkClick()
    navigate('/')
  }

  const handleNavLinkClick = () => {
    setExpanded(false)
  }

  useEffect(() => {
    const email = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    setUser({ email, role })
  }, [isLoggedIn])

  // Collapse navbar on location change
  useEffect(() => {
    setExpanded(false)
  }, [location])

  return (
    <>
      {isLoggedIn && location.pathname !== '/add-profile' && (
        <Navbar
          bg="dark"
          variant="dark"
          expand="lg"
          expanded={expanded}
          className={'navbar-black'}
        >
          <Container>
            <Navbar.Brand as={NavLink} to="/">
              <img src={logo} alt="" className="logo-image" />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setExpanded(expanded ? false : 'expanded')}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {isLoggedIn ? (
                  user.role === 'admin' ? (
                    <>
                      <Nav.Link
                        as={NavLink}
                        to="/"
                        onClick={handleNavLinkClick}
                      >
                        Stories
                      </Nav.Link>
                      <Nav.Link
                        as={NavLink}
                        to="/dashboard"
                        end
                        onClick={handleNavLinkClick}
                      >
                        Dashboard
                      </Nav.Link>
                      <Nav.Link
                        as={NavLink}
                        to="/books"
                        onClick={handleNavLinkClick}
                      >
                        Books
                      </Nav.Link>
                      <Nav.Link
                        as={NavLink}
                        to="/add-book"
                        onClick={handleNavLinkClick}
                      >
                        Add Book
                      </Nav.Link>
                      <Nav.Link onClick={logout}>Logout</Nav.Link>
                    </>
                  ) : (
                    <>
                      <Nav.Link
                        as={NavLink}
                        to="/"
                        onClick={handleNavLinkClick}
                      >
                        Stories
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => {
                          handleNavLinkClick()
                          logout()
                        }}
                      >
                        Logout
                      </Nav.Link>
                    </>
                  )
                ) : (
                  <>
                    <Nav.Link
                      as={NavLink}
                      to="/login"
                      onClick={() => {
                        handleNavLinkClick()
                      }}
                    >
                      Login
                    </Nav.Link>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
      {isLoggedIn ? (
        user?.role === 'admin' ? (
          <Routes>
            <Route path="/" element={<Stories isLoggedIn={isLoggedIn} />} />
            <Route path="/dashboard" element={<Home />} />
            <Route path="/books" element={<Books isLoggedIn={isLoggedIn} />} />
            <Route
              path="/book-detail/:title"
              element={<BookDetails isLoggedIn={isLoggedIn} user={user} />}
            />
            <Route path="/add-book" element={<AddBook />} />
            <Route path="/update-book/:id" element={<UpdateBook />} />
            <Route path="/:bookName" element={<PdfMain />} />
            <Route path="/add-profile" element={<AddProfile />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Stories isLoggedIn={isLoggedIn} />} />
            <Route
              path="/book-detail/:title"
              element={<BookDetails isLoggedIn={isLoggedIn} />}
            />
            <Route path="/:bookName" element={<PdfMain />} />
            <Route path="/add-profile" element={<AddProfile />} />
          </Routes>
        )
      ) : (
        <Routes>
          {<Route
            path="/"
            element={
              <Stories
                isheaderNotFixed={true}
                isLoggedIn={isLoggedIn}
                setIsLoggenIn={setIsLoggenIn}
              />
            }
          /> }
          <Route
            path="/book-detail/:title"
            element={<BookDetails isLoggedIn={false} />}
          />
          <Route path="/:bookName" element={<PdfMain />} />
        </Routes>
      )}
    </>
  )
}

const AppWrapper = () => (
  <Router>
    <App />
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </Router>
)

export default AppWrapper
