import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import dayjs from "dayjs";

import AddProfileimg1 from "../../assets/AddProfileImgs/A1.png";
import AddProfileimg2 from "../../assets/AddProfileImgs/A2.png";
import AddProfileimg3 from "../../assets/AddProfileImgs/A3.png";
import AddProfileimg4 from "../../assets/AddProfileImgs/A4.png";
import AddProfileimg5 from "../../assets/AddProfileImgs/A5.png";
import AddProfileimg6 from "../../assets/AddProfileImgs/A6.png";
import AddProfileimg7 from "../../assets/AddProfileImgs/A7.png";
import AddProfileimg8 from "../../assets/AddProfileImgs/A8.png";
import AddProfileimg9 from "../../assets/AddProfileImgs/A9.png";
import close from "../../assets/svg/close.svg";
import config from '../../Helpers/config.json'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import "react-slideshow-image/dist/styles.css";
import "./style.css";

const AddProfileModal = ({
  show,
  onHide,
  setShowLoginPopup,
  closeAll,
  setAuthInfo,
  authInfo,
  setIsLoggenIn
}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [sliderImages, setSliderImages] = useState([
    {
      profile1: AddProfileimg1,
      profile2: AddProfileimg2,
      profile3: AddProfileimg3,
      isprofile1: false,
      isprofile2: false,
      isprofile3: false,
    },
    {
      profile2: AddProfileimg4,
      profile3: AddProfileimg5,
      profile4: AddProfileimg6,
      isprofile2: false,
      isprofile3: false,
      isprofile4: false,
    },
    {
      profile3: AddProfileimg7,
      profile4: AddProfileimg8,
      profile5: AddProfileimg9,
      isprofile3: false,
      isprofile4: false,
      isprofile5: false,
    },
  ]);

  const formik = useFormik({
    initialValues: {
      name: "",
      dob: new Date(),
      gender: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is Required"),
      dob: Yup.string().required("Date of birth is Required"),
      gender: Yup.string().required("Gender is Required"),
    }),
  });

  const selectImage = (index, key) => {
    const tempAllImage = sliderImages.map((item, idx) => {
      if (idx === 0) {
        return {
          ...item,
          isprofile1: false,
          isprofile2: false,
          isprofile3: false,
        };
      } else if (idx === 1) {
        return {
          ...item,
          isprofile2: false,
          isprofile3: false,
          isprofile4: false,
        };
      } else if (idx === 2) {
        return {
          ...item,
          isprofile3: false,
          isprofile4: false,
          isprofile5: false,
        };
      } else {
        return item;
      }
    });

    const test = {
      ...tempAllImage[index],
      [`is${key}`]: !sliderImages[index][`is${key}`],
    };
    tempAllImage.splice(index, 1, test);
    formik.setFieldValue('image', sliderImages[index][key])
    setSliderImages(tempAllImage);
  };

  const addProfile = () => {
    setLoading(true);
    const uid = JSON.parse(localStorage.getItem('data'))?.uid;
    axios.post(`${config['baseUrl2']}profile/createProfile`, {
      uid: uid,
      name: formik.values.name,
      gender: formik.values.gender,
      image: formik.values.image,
      categories: "1",
      dob: new Date(formik.values.dob).toISOString()
    })
      .then(response => {
        const data = response.data;
        if (data.success) {
          setLoading(false);
          closeAll()
          setIsLoggenIn(true)
          navigate('/')
        } else {
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.error('Error:', error);
      });
  };

  console.log('formik ', formik.values)

  return (
    <Modal size="lg" show={show} centered className="modal-add-profile">
      <Modal.Body>
        <span onClick={closeAll}>
          <img src={close} alt="" className="close-icon" />
        </span>
        <div>
          <h4 className="fw-bold text-center">Add Profile</h4>
        </div>
        <div className="emoji-container">
          <Slide autoplay={false}>
            {(sliderImages || [])?.map((slideImage, index) => (
              <div
                className="d-flex justify-content-center align-items-center"
                key={index}
              >
                <div
                  className={`me-3 text-center ${slideImage[`isprofile${index + 1}`] &&
                    "active-image-profile"
                    } `}
                  onClick={() => selectImage(index, `profile${index + 1}`)}
                >
                  <img
                    src={`${slideImage[`profile${index + 1}`]}`}
                    alt=""
                    width={80}
                    height={80}
                  />
                </div>
                <div
                  className={`me-3 text-center ${slideImage[`isprofile${index + 2}`] &&
                    "active-image-profile"
                    } `}
                  onClick={() => selectImage(index, `profile${index + 2}`)}
                >
                  <img
                    src={`${slideImage[`profile${index + 2}`]}`}
                    alt=""
                    width={80}
                    height={80}
                  />
                </div>
                <div
                  className={`me-3 text-center ${slideImage[`isprofile${index + 3}`] &&
                    "active-image-profile"
                    } `}
                  onClick={() => selectImage(index, `profile${index + 3}`)}
                >
                  <img
                    src={`${slideImage[`profile${index + 3}`]}`}
                    alt=""
                    width={80}
                    height={80}
                  />
                </div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="mt-0">
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.name && !!formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          {/* <Form.Group className="mb-3">
            <Form.Label>Date of birth</Form.Label>
            <DatePicker
              style={{ color: "black" }}
              value={
                formik.values.dob
                  ? dayjs(formik.values.dob, "DD-MM-YYYY")
                  : null
              }
              className="profile-date"
              format="DD/MM/YYYY"
              onChange={(date) =>
                formik.setFieldValue(
                  "dob",
                  date ? dayjs(date).format("DD-MM-YYYY") : null
                )
              }
              defaultValue={dayjs("01-01-2012", "DD-MM-YYYY")}
              onBlur={formik.handleBlur}
              id="dob"
              name="dob"
              popupClassName='date-picker-popup'
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.dob}
            </Form.Control.Feedback>
          </Form.Group> */}
          <Form.Group className="mb-3">
            <Form.Label>Date of Birth</Form.Label>
            <div>
              <DatePicker 
                selected={formik.values.dob} 
                id="dob"
                name="dob"
                value={formik.values.dob}
                onBlur={formik.handleBlur}
                onChange={(date) => formik.setFieldValue('dob', date)} 
              />
            </div>
            {/* <Form.Control
              type="text"
              placeholder="DD-MM-YYYY"
              id="dob"
              name="dob"
              value={formik.values.dob}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.dob && !!formik.errors.dob}
            /> */}
            <Form.Control.Feedback type="invalid">
              {formik.errors.dob}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Gender</Form.Label>
            <Form.Select
              id="gender"
              name="gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.gender && !!formik.errors.gender}
            >
              <option>select</option>
              <option value="m">Male</option>
              <option value="f">Female</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.gender}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="text-center footer-profile">
          <Button
            className="me-2"
            variant="dark"
            disabled={!formik.isValid || !formik.dirty}
            onClick={addProfile}
          >
            {loading ? <Spinner animation="border" /> : "Add profile"}
          </Button>
          <Button className="me-2" variant="light" onClick={closeAll}>
            Cancel
          </Button>{" "}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddProfileModal;
